import React from "react"
import styled, { css } from "styled-components"
import { Card } from "@mui/material"
import { Link } from "gatsby-plugin-react-intl"

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledCard = styled(props => <Card {...props} />)`
  max-width: 400px;
  margin: 0 auto;
`

export const Title = styled.h2`
  text-align: center;
  margin: 0.4rem 0 1rem 0;
`

export const RegistrationUnavailable = styled.div`
  background: #fffdd5;
  margin: 0 0 5px 0;
  padding: 15px;
  border: 2px solid #ffeab1;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const GoToLoginSection = styled.div`
  display: inline;
`

export const GoToLoginLink = styled(props => <Link {...props} />)`
  display: inline;
  text-decoration: none;
`

export const ConsentLink = styled.a`
  display: inline;
  text-decoration: none;
`

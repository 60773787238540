import React, { useContext, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-react-intl"
import { RouteComponentProps } from "@reach/router"

import * as S from "./styles"
import { AuthContext } from "../../../../containers/AuthContext"
import { AuthLayout } from "../AuthLayout/AuthLayout"
import { CardContent, TextField, Button } from "@mui/material"

const LoginPage: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  const { register, handleSubmit, setError, control } = useForm()
  const [isError, setIsError] = useState(false)

  const { login } = useContext(AuthContext)

  const onSubmit = async data => {
    if (isError) {
      setIsError(false)
    }

    const response = await login({
      username: data.username,
      password: data.password,
    })

    if (response.errors?.length) {
      setIsError(true)
    } else {
      navigate("/")
    }
  }

  return (
    <AuthLayout>
      <S.StyledCard>
        <CardContent>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.Title>
              <FormattedMessage defaultMessage="Login" description="title" />
            </S.Title>
            {isError && (
              <FormattedMessage
                defaultMessage="Login incorrect"
                description="error message"
              />
            )}
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label={intl.formatMessage({
                    defaultMessage: "Username",
                    description: "input label",
                  })}
                  variant="outlined"
                  fullWidth
                  type="text"
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label={intl.formatMessage({
                    defaultMessage: "Password",
                    description: "input label",
                  })}
                  variant="outlined"
                  fullWidth
                  type="password"
                  {...field}
                />
              )}
            />
            <S.GoToRegisterSection>
              <FormattedMessage
                defaultMessage="Don't have an account yet?"
                description="info message"
              />{" "}
              <S.GoToRegisterLink to="/auth/register">
                <FormattedMessage
                  defaultMessage="Create it here."
                  description="info message"
                />
              </S.GoToRegisterLink>
            </S.GoToRegisterSection>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disableElevation
              type="submit"
            >
              <FormattedMessage defaultMessage="Log in" description="button" />
            </Button>
          </S.Form>
        </CardContent>
      </S.StyledCard>
    </AuthLayout>
  )
}

export { LoginPage }

import React from "react"
import styled from "styled-components"
import { Card } from "@mui/material"

export const StyledCard = styled(props => <Card {...props} />)`
  max-width: 400px;
  margin: 0 auto;
`

export const Title = styled.h2`
  text-align: center;
  margin: 0.4rem 0 1rem 0;
`

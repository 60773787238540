import { gql } from "@apollo/client"

export const createAccountMutation = gql`
  mutation CreateAccount(
    $username: String!
    $email: String!
    $password: String!
    $language: String
  ) {
    createAccount(
      username: $username
      email: $email
      password: $password
      language: $language
    ) {
      user {
        id
        username
        firstName
        lastName
        email
      }
    }
  }
`

import { FormattedMessage, navigate } from "gatsby-plugin-react-intl"
import React from "react"
import { ThemeProvider } from "styled-components"

import theme from "../../../../gatsby-theme-material-ui-top-layout/theme"
import * as S from "./style"

const AuthLayout: React.FC = ({ children }) => {
  const handleGoBack = () => {
    navigate("/")
  }

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <S.BackLinkContainer>
          <S.BackButton onClick={handleGoBack} />
          <S.BackLink to="/">
            <FormattedMessage defaultMessage="Home" description="link label" />
          </S.BackLink>
        </S.BackLinkContainer>
        <S.HeaderSection>
          <S.Title>LearnTray</S.Title>
        </S.HeaderSection>
        {children}
      </S.Wrapper>
    </ThemeProvider>
  )
}

export { AuthLayout }

import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { RouteComponentProps } from "@reach/router"
import { CardContent } from "@mui/material"

import * as S from "./styles"
import { AuthLayout } from "../AuthLayout/AuthLayout"

const RegisterConfirmRequiredPage: React.FC<RouteComponentProps> = () => {
  return (
    <AuthLayout>
      <S.StyledCard>
        <CardContent>
          <S.Title>
            <FormattedMessage
              defaultMessage="Registration"
              description="title"
            />
          </S.Title>
          <FormattedMessage
            defaultMessage="The account has been created. Before logging in, click on the link confirming the creation of the account sent to your e-mail."
            description="description"
          />
        </CardContent>
      </S.StyledCard>
    </AuthLayout>
  )
}

export { RegisterConfirmRequiredPage }

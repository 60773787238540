import React from "react"
import styled from "styled-components"
import { IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Link } from "gatsby-plugin-react-intl"

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: block;
  background: linear-gradient(
    0deg,
    rgb(0, 127, 255) 0%,
    rgb(76, 130, 255) 100%
  );
  color: #fff;
  padding: 0 0 40px 0;

  @media screen and (min-width: 800px) {
    padding: 0 0 80px 0;
  }
`

export const HeaderSection = styled.section`
  padding: 20px 30px 10px 30px;

  @media screen and (min-width: 800px) {
    padding: 60px 80px 20px 80px;
  }
  @media screen and (min-width: 1200px) {
    padding: 60px 20% 20px 20%;
  }
`

export const Title = styled.h1`
  text-align: center;
  font-size: 1.4rem;

  @media screen and (min-width: 800px) {
    font-size: 2rem;
  }
`

export const BackLinkContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 20px 10px;

  @media screen and (min-width: 800px) {
    padding: 10px 10px 40px 10px;
  }
`

export const BackButton = styled(props => (
  <IconButton color="inherit" aria-label="open drawer" edge="start" {...props}>
    <ArrowBackIcon />
  </IconButton>
))`
  margin-left: 0;
`

export const BackLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: #fff;
  font-size: 1.2em;
`

import { useMutation } from "@apollo/client"
import {
  CardContent,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { RouteComponentProps } from "@reach/router"
import {
  defineMessages,
  FormattedMessage,
  navigate,
  useIntl,
} from "gatsby-plugin-react-intl"
import { Button } from "gatsby-theme-material-ui"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntlPathPrefix } from "../../../../hooks/useIntlPathPrefix"
import { AuthLayout } from "../AuthLayout/AuthLayout"
import { createAccountMutation } from "./mutations"

import * as S from "./styles"

const errorMessages = defineMessages({
  usernameIllegal: {
    defaultMessage: "A user cannot be created with this username.",
    description: "error message",
  },
  usernameExists: {
    defaultMessage: "A user with this username already exists.",
    description: "error message",
  },
  emailExists: {
    defaultMessage: "A user with this email address already exists.",
    description: "error message",
  },
  unknown: {
    defaultMessage: "Account creation failed.",
    description: "error message",
  },
})

const RegisterPage: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  const intlPathPrefix = useIntlPathPrefix()
  const { register, getValues, handleSubmit, control } = useForm()
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState("")
  const [createAccount, createAccountOpts] = useMutation(
    createAccountMutation,
    { errorPolicy: "all" }
  )
  const [privacyPolicyConsent, setPrivacyPolicyConsent] = useState(false)
  const [termsOfService, setTermsOfService] = useState(false)
  const [peopleAcceptanceConsent, setPeopleAcceptanceConsent] = useState(false)

  const onSubmit = async data => {
    if (isError) {
      setIsError(false)
      setError("")
    }

    const response = await createAccount({
      variables: {
        username: data.username,
        email: data.email,
        password: data.password,
        language: "pl",
      },
    })

    if (response.errors?.length) {
      setIsError(true)
      setError(response.errors[0].message)
    } else {
      navigate("/auth/register-confirm")
    }
  }

  return (
    <AuthLayout>
      <S.Container>
        <S.StyledCard>
          <CardContent>
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              <S.Title>
                <FormattedMessage
                  defaultMessage="Registration"
                  description="title"
                />
              </S.Title>
              {/* <S.RegistrationUnavailable>
                <FormattedMessage
                  defaultMessage={`
                  We are sorry, registration is not available yet - for better comfort and 
                  protection of your privacy, the privacy policy and terms of service are 
                  still under review. Please come back later.
                `}
                  description="info message"
                />
              </S.RegistrationUnavailable> */}
              {isError &&
                (error === "username illegal" ? (
                  <FormattedMessage {...errorMessages.usernameIllegal} />
                ) : error === "username exists" ? (
                  <FormattedMessage {...errorMessages.usernameExists} />
                ) : error === "email exists" ? (
                  <FormattedMessage {...errorMessages.emailExists} />
                ) : (
                  <FormattedMessage {...errorMessages.unknown} />
                ))}
              <Controller
                name="username"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label={intl.formatMessage({
                      defaultMessage: "Username",
                      description: "input label",
                    })}
                    variant="outlined"
                    fullWidth
                    type="text"
                    {...field}
                    // disabled // temporaily disabled
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label={intl.formatMessage({
                      defaultMessage: "Email",
                      description: "input label",
                    })}
                    variant="outlined"
                    fullWidth
                    type="email"
                    {...field}
                    // disabled // temporaily disabled
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label={intl.formatMessage({
                      defaultMessage: "Password",
                      description: "input label",
                    })}
                    variant="outlined"
                    fullWidth
                    type="password"
                    {...field}
                    // disabled // temporaily disabled
                  />
                )}
              />
              <FormControlLabel
                value="terms-of-service-consent"
                control={
                  <Checkbox
                    color="primary"
                    name="terms-of-service-consent"
                    checked={termsOfService}
                    onChange={e => setTermsOfService(e.target.checked)}
                    // disabled // temporaily disabled
                  />
                }
                label={intl.formatMessage(
                  {
                    defaultMessage:
                      "*I agree with {termsOfServiceLink} and therefore I am over 18 years old or over 16 years old having parent or legal guardian's permission to use service.",
                    description: "checkbox label",
                  },
                  {
                    termsOfServiceLink: (
                      <S.ConsentLink
                        href={`${intlPathPrefix}/terms-of-service`}
                        target="_blank"
                        rel="noopener"
                      >
                        <FormattedMessage
                          defaultMessage="terms of service"
                          description="link"
                        />
                      </S.ConsentLink>
                    ),
                  }
                )}
                labelPlacement="end"
              />
              <FormControlLabel
                value="privacy-policy-consent"
                control={
                  <Checkbox
                    color="primary"
                    name="privacy-policy-consent"
                    checked={privacyPolicyConsent}
                    onChange={e => setPrivacyPolicyConsent(e.target.checked)}
                    // disabled // temporaily disabled
                  />
                }
                label={intl.formatMessage(
                  {
                    defaultMessage:
                      "*I agree with LearnTray to collect, use and share my personal data and use cookies and similar technology to provide me with service functionality, what is described in {privacyPolicyLink}.",
                    description: "checkbox label",
                  },
                  {
                    privacyPolicyLink: (
                      <S.ConsentLink
                        href={`${intlPathPrefix}/privacy-policy`}
                        target="_blank"
                        rel="noopener"
                      >
                        <FormattedMessage
                          defaultMessage="privacy policy"
                          description="link"
                        />
                      </S.ConsentLink>
                    ),
                  }
                )}
                labelPlacement="end"
              />
              <S.GoToLoginSection>
                <FormattedMessage
                  defaultMessage="Already have an account?"
                  description="info message"
                />{" "}
                <S.GoToLoginLink to="/auth/login">
                  <FormattedMessage
                    defaultMessage="Log in."
                    description="info message"
                  />
                </S.GoToLoginLink>
              </S.GoToLoginSection>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disableElevation
                type="submit"
                disabled={!termsOfService || !privacyPolicyConsent}
              >
                <FormattedMessage
                  defaultMessage="Create account"
                  description="button"
                />
              </Button>
            </S.Form>
          </CardContent>
        </S.StyledCard>
      </S.Container>
    </AuthLayout>
  )
}
export { RegisterPage }

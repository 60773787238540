import React, { useEffect, useState } from "react"
import { FormattedMessage, navigate } from "gatsby-plugin-react-intl"
import { RouteComponentProps, useLocation } from "@reach/router"

import * as S from "./styles"
import { useMutation } from "@apollo/client"
import { confirmAccountMutation } from "./mutations"
import queryString from "query-string"
import { AuthLayout } from "../AuthLayout/AuthLayout"
import { CardContent, CircularProgress, Button } from "@mui/material"

const isBrowser = typeof window !== `undefined`

const ConfirmAccountPage: React.FC<RouteComponentProps> = () => {
  const location = useLocation()
  const queryData = queryString.parse(location.search)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [confirmAccount, confirmAccountOpts] = useMutation(
    confirmAccountMutation,
    {
      errorPolicy: "all",
    }
  )

  const submitConfirmAccount = async () => {
    if (isError) {
      setIsError(false)
    }

    const response = await confirmAccount({
      variables: {
        email: queryData.email || "",
        token: queryData.token || "",
      },
    })

    if (
      response.errors?.length ||
      !response.data?.confirmAccount?.confirmationSuccess
    ) {
      setIsError(true)
    } else {
      setIsSuccess(true)
    }
  }

  useEffect(() => {
    if (isBrowser) {
      submitConfirmAccount()
    }
  }, [])

  const handleGoToLogin = () => {
    navigate("/auth/login")
  }

  return (
    <AuthLayout>
      <S.StyledCard>
        <CardContent>
          <S.Title>
            <FormattedMessage
              defaultMessage="Account confirmation"
              description="title"
            />
          </S.Title>
          <S.Description>
            {!isBrowser ? null : confirmAccountOpts.loading ? (
              <CircularProgress color="primary" />
            ) : isError ? (
              <FormattedMessage
                defaultMessage="Account confirmation failed."
                description="description"
              />
            ) : (
              isSuccess && (
                <>
                  <div>
                    <FormattedMessage
                      defaultMessage="The account for {email} has been confirmed."
                      description="description"
                      values={{
                        email: <b>{queryData.email}</b>,
                      }}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    disableElevation
                    onClick={handleGoToLogin}
                  >
                    <FormattedMessage
                      defaultMessage="Go to login"
                      description="button"
                    />
                  </Button>
                </>
              )
            )}
          </S.Description>
        </CardContent>
      </S.StyledCard>
    </AuthLayout>
  )
}

export { ConfirmAccountPage }

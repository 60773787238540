import React, { useContext, useEffect } from "react"
import { Router } from "@reach/router"
import { LoginPage } from "../components/sets/auth/LoginPage"
import { AuthContext } from "../containers/AuthContext"
import { RegisterPage } from "../components/sets/auth/RegisterPage"
import { RegisterConfirmRequiredPage } from "../components/sets/auth/RegisterConfirmRequiredPage"
import { ConfirmAccountPage } from "../components/sets/auth/ConfirmAccountPage"
import { SEO } from "../components/atoms/SEO"
import { navigate } from "gatsby-plugin-react-intl"
import { useIntlPathPrefix } from "../hooks/useIntlPathPrefix"

const Auth: React.FC = () => {
  const { authenticated, loading } = useContext(AuthContext)
  const pathPrefix = useIntlPathPrefix()

  useEffect(() => {
    if (authenticated) {
      navigate("/courses")
    }
  }, [])

  if (authenticated) {
    return null
  }

  return (
    <>
      <SEO />
      <Router basepath={`${pathPrefix}/auth`}>
        <LoginPage path="/login" />
        <RegisterConfirmRequiredPage path="/register-confirm" />
        <RegisterPage path="/register" />
        <ConfirmAccountPage path="/confirm-account" />
      </Router>
    </>
  )
}

export default Auth

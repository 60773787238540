import React from "react"
import styled from "styled-components"
import { Card } from "@mui/material"
import { Link } from "gatsby-plugin-react-intl"

export const StyledCard = styled(props => <Card {...props} />)`
  max-width: 400px;
  margin: 0 auto;
`

export const Title = styled.h2`
  text-align: center;
  margin: 0.4rem 0 1rem 0;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const GoToRegisterSection = styled.div`
  display: inline;
`

export const GoToRegisterLink = styled(props => <Link {...props} />)`
  display: inline;
  text-decoration: none;
`
